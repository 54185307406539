<template>
  <div accordion-item v-editable="blok" :class="{ open: open }" ref="accordion">
    <div class="accordion-header" @click="toggleActive">
      <span class="accordion-marker" v-if="blok.marker" v-text="blok.marker"></span>
      <h3 class="accordion-title" v-text="blok.title"></h3>
    </div>
    <div class="accordion-body" :style="[maxHeight]">
      <div class="accordion-content">
        <Vue3RuntimeTemplate :template="content" />
      </div>
    </div>
  </div>
</template>
<style src="~/assets/scss/section/accordion-item.scss" lang="scss"></style>
<script setup>
import Vue3RuntimeTemplate from "vue3-runtime-template";
const props = defineProps({ blok: Object, open: Boolean, value: Number });

const active = defineModel();

const accordion = ref(null);
const content = computed(() => useRenderRichText(props?.blok?.content || {}));
const contentHeight = ref(0);

const maxHeight = computed(() => {
  if (props.open) return { maxHeight: contentHeight.value + "px" };
  return false;
});

const toggleActive = () => {
  if (active.value !== props.value) {
    active.value = props.value;
  }
};

watchEffect(async () => {
  if (props.open && accordion?.value) {
    contentHeight.value = parseFloat(accordion.value.querySelector(".accordion-content").clientHeight) + 32;
  }
});
</script>
